import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import { API, getLogo, showError, showInfo, showSuccess } from '../helpers';
import Turnstile from 'react-turnstile';

const RegisterForm = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    password2: '',
    email: '',
    verification_code: ''
  });
  const { username, password, password2 } = inputs;
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [turnstileEnabled, setTurnstileEnabled] = useState(false);
  const [turnstileSiteKey, setTurnstileSiteKey] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const [loading, setLoading] = useState(false);
  const logo = getLogo();
  let affCode = new URLSearchParams(window.location.search).get('aff');
  if (affCode) {
    localStorage.setItem('aff', affCode);
  }

  const [verificationSent, setVerificationSent] = useState(false);

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      setShowEmailVerification(status.email_verification);
      if (status.turnstile_check) {
        setTurnstileEnabled(true);
        setTurnstileSiteKey(status.turnstile_site_key);
      }
    }
  });

  let navigate = useNavigate();

  function handleChange(e) {
    const { name, value } = e.target;
    console.log(name, value);
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    if (password.length < 8) {
      showInfo('密碼長度不得小於 8 位數！');
      return;
    }
    if (password !== password2) {
      showInfo('兩次輸入的密碼不一致');
      return;
    }
    if (username && password) {
      if (turnstileEnabled && turnstileToken === '') {
        showInfo('請稍後幾秒重試，Turnstile 正在檢查用戶環境！');
        return;
      }
      setLoading(true);
      if (!affCode) {
        affCode = localStorage.getItem('aff');
      }
      inputs.aff_code = affCode;
      const res = await API.post(
        `/api/user/register?turnstile=${turnstileToken}`,
        inputs
      );
      const { success, message } = res.data;
      if (success) {
        navigate('/login');
        showSuccess('註冊成功！');
      } else {
        showError(message);
      }
      setLoading(false);
    }
  }

  const sendVerificationCode = async () => {
    if (inputs.email === '') return;
    if (turnstileEnabled && turnstileToken === '') {
      showInfo('請稍後幾秒重試，Turnstile 正在檢查用戶環境！');
      return;
    }
    setLoading(true);
    const res = await API.get(
      `/api/verification?email=${inputs.email}&turnstile=${turnstileToken}`
    );
    const { success, message } = res.data;
    if (success) {
      showSuccess('驗證碼發送成功，請檢查您的電子信箱！');
      setVerificationSent(true);
    } else {
      showError(message);
    }
    setLoading(false);
  };

  const handleVerificationCodeFocus = () => {
    if (!verificationSent) {
      showInfo('請先填妥信箱地址，並點擊"發送驗證碼"按鈕');
    }
  };

  return (
    <Grid textAlign='center' style={{ marginTop: '48px' }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' color='' textAlign='center'>
          <Image src={logo} /> 新用戶註冊
        </Header>
        <Message warning>
          <span id="announcement-message">
            系統公告：部分郵件服務（如 Outlook, Hotmail, Yahoo 等）可能無法收到系統信件，包括驗證信和客服郵件。
            <br />請改用其他郵件服務。
          </span>
        </Message>
        <Form size='large'>
          <Segment>
            <Form.Input
              fluid
              icon='user'
              iconPosition='left'
              placeholder='輸入用戶名，最長 12 位'
              onChange={handleChange}
              name='username'
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='輸入密碼，最短 8 位，最長 20 位'
              onChange={handleChange}
              name='password'
              type='password'
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='輸入密碼，最短 8 位，最長 20 位'
              onChange={handleChange}
              name='password2'
              type='password'
            />
            <Form.Input
              fluid
              icon='mail'
              iconPosition='left'
              placeholder='輸入電子信箱地址'
              onChange={handleChange}
              name='email'
              type='email'
              action={
                <Button color='teal' onClick={sendVerificationCode} disabled={loading}>
                  發送驗證碼
                </Button>
              }
            />
            <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='輸入驗證碼'
              onChange={handleChange}
              name='verification_code'
              readOnly={!verificationSent}
              onFocus={handleVerificationCodeFocus}
            />
            {turnstileEnabled ? (
              <Turnstile
                sitekey={turnstileSiteKey}
                onVerify={(token) => {
                  setTurnstileToken(token);
                }}
              />
            ) : null}
            <Button
              color='blue'
              fluid
              size='large'
              onClick={handleSubmit}
              loading={loading}
            >
              註冊
            </Button>
          </Segment>
        </Form>
        <Message>
          已有帳號？
          <Link to='/login' className='btn btn-link'>
            登入
          </Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default RegisterForm;
