import React, { useContext, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/User';

import { Button, Container, Dropdown, Icon, Menu, Segment } from 'semantic-ui-react';
import { API, getLogo, getSystemName, isAdmin, isMobile } from '../helpers';
import '../index.css';

// Header Buttons
let headerButtons = [
  // {
  //   name: '首頁',
  //   to: '/',
  //   icon: 'home'
  // },
  {
    name: '使用指南',
    to: 'https://docs.stima.tech',
    icon: 'book',
    external: true  // 添加這個屬性來標記外部連結
  },
  {
    name: '管道',
    to: '/channel',
    icon: 'sitemap',
    admin: true
  },
  {
    name: '購買',
    to: 'https://payment.stima.tech',
    icon: 'credit card',
    external: true
  },
  {
    name: 'API Key',
    to: '/token',
    icon: 'key'
  },
  {
    name: '兌換管理',
    to: '/redemption',
    icon: 'dollar sign',
    admin: true
  },
  {
    name: '加值',
    to: '/topup',
    icon: 'plus'
  },
  {
    name: '使用者',
    to: '/user',
    icon: 'user',
    admin: true
  },
  {
    name: '日誌',
    to: '/log',
    icon: 'history',
  },
  {
    name: '設定',
    to: '/setting',
    icon: 'setting'
  },
  // {
  //   name: '關於',
  //   to: '/about',
  //   icon: 'info circle'
  // }
];

// if (localStorage.getItem('chat_link')) {
//   headerButtons.splice(1, 0, {
//     name: '聊天',
//     to: '/chat',
//     icon: 'comments'
//   });
// }

const Header = () => {
  const [userState, userDispatch] = useContext(UserContext);
  let navigate = useNavigate();
  let location = useLocation();

  const [showSidebar, setShowSidebar] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const systemName = getSystemName();
  const logo = getLogo();

  const headerStyle = {
    background: '#ffffff',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    borderBottom: '1px solid #e0e0e0',
    padding: '10px 0',  // 增加上下內邊距
    height: '70px',    // 增加固定高度
    display: 'flex',
    alignItems: 'center',
  };

  const logoStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '22px',  // 增加字體大小
    fontWeight: 'bold',
    color: '#333333',
  };

  const logoImageStyle = {
    width: 'auto',
    height: '45px',  // 增加 logo 高度
    marginRight: '0.5em',
    objectFit: 'contain'
  };

  const buttonStyle = (isActive, isHovered) => ({
    background: 'transparent',
    color: isActive || isHovered ? '#1a73e8' : '#555555',
    fontWeight: '500',
    transition: 'all 0.3s ease',
    borderBottom: isActive ? '2px solid #1a73e8' : isHovered ? '2px solid #1a73e8' : 'none',
    paddingBottom: isActive || isHovered ? '6px' : '8px',  // 調整下內邊距
    height: '100%',  // 確保按鈕高度填滿 header
    display: 'flex',
    alignItems: 'center',
  });

  async function logout() {
    setShowSidebar(false);
    await API.get('/api/user/logout');
    // showSuccess('登出成功!');
    userDispatch({ type: 'logout' });
    localStorage.removeItem('user');
    navigate('/login');
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const renderButtons = (isMobile) => {
    return headerButtons.map((button) => {
      if (button.admin && !isAdmin()) return <></>;
      const isActive = location.pathname === button.to;
      const isHovered = hoveredItem === button.name;
      if (isMobile) {
        return (
          <Menu.Item
            key={button.name}
            onClick={() => {
              if (button.external) {
                window.open(button.to, '_blank', 'noopener,noreferrer');
              } else {
                navigate(button.to);
              }
              setShowSidebar(false);
            }}
          >
            {button.name}
          </Menu.Item>
        );
      }
      return (
        <Menu.Item 
          key={button.name} 
          as={button.external ? 'a' : Link}  // 根據是否為外部連結使用不同的元素
          to={button.external ? undefined : button.to}  // 如果是外部連結，不使用 to 屬性
          href={button.external ? button.to : undefined}  // 如果是外部連結，使用 href 屬性
          target={button.external ? '_blank' : undefined}  // 外部連結在新標籤頁打開
          rel={button.external ? 'noopener noreferrer' : undefined}  // 外部連結的安全設置
          style={buttonStyle(isActive, isHovered)}
          onMouseEnter={() => setHoveredItem(button.name)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <Icon name={button.icon} style={{marginRight: '5px'}} />
          {button.name}
        </Menu.Item>
      );
    });
  };

  if (isMobile()) {
    return (
      <>
        <Menu
          borderless
          size='large'
          style={{
            ...headerStyle,
            marginBottom: showSidebar ? '0' : undefined,
          }}
        >
          <Container>
            <Menu.Item as={Link} to='/' style={logoStyle}>
              <img
                src={logo}
                alt='logo'
                style={logoImageStyle}
              />
              <div>{systemName}</div>
            </Menu.Item>
            <Menu.Menu position='right'>
              <Menu.Item onClick={toggleSidebar}>
                <Icon name={showSidebar ? 'close' : 'sidebar'} size='large' style={{color: '#555555'}} />
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
        {showSidebar && (
          <Segment style={{ marginTop: 0, borderTop: '0', background: '#f8f9fa' }}>
            <Menu secondary vertical style={{ width: '100%', margin: 0 }}>
              {renderButtons(true)}
              <Menu.Item>
                {userState.user ? (
                  <Button onClick={logout} style={{background: '#1a73e8', color: '#ffffff'}}>登出</Button>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setShowSidebar(false);
                        navigate('/login');
                      }}
                      style={{background: '#1a73e8', color: '#ffffff', marginRight: '10px'}}
                    >
                      登入
                    </Button>
                    <Button
                      onClick={() => {
                        setShowSidebar(false);
                        navigate('/register');
                      }}
                      style={{background: '#1a73e8', color: '#ffffff'}}
                    >
                      註冊
                    </Button>
                  </>
                )}
              </Menu.Item>
            </Menu>
          </Segment>
        )}
      </>
    );
  }

  return (
    <Menu borderless style={headerStyle}>
      <Container style={{height: '100%'}}>
        <Menu.Item as={Link} to='/' className={'hide-on-mobile'} style={{...logoStyle, height: '100%'}}>
          <img src={logo} alt='logo' style={logoImageStyle} />
          <div>{systemName}</div>
        </Menu.Item>
        {renderButtons(false)}
        <Menu.Menu position='right' style={{height: '100%'}}>
          {userState.user ? (
            <Dropdown
              text={userState.user.username}
              pointing
              className='link item'
              style={{...buttonStyle(false, hoveredItem === 'user-dropdown'), height: '100%'}}
              onMouseEnter={() => setHoveredItem('user-dropdown')}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={logout}>登出</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Menu.Item
              name='登入'
              as={Link}
              to='/login'
              style={{...buttonStyle(location.pathname === '/login', hoveredItem === 'login'), height: '100%'}}
              onMouseEnter={() => setHoveredItem('login')}
              onMouseLeave={() => setHoveredItem(null)}
            />
          )}
        </Menu.Menu>
      </Container>
    </Menu>
  );
};

export default Header;
