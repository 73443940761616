import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Grid, Header, Image, Divider, Modal } from 'semantic-ui-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../context/User';
import { API, getLogo, showError, showSuccess, showWarning } from '../helpers';
import { onGitHubOAuthClicked, onLarkOAuthClicked } from './utils';
import larkIcon from '../images/lark.svg';

const LoginForm = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    wechat_verification_code: ''
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  const [userState, userDispatch] = useContext(UserContext);
  let navigate = useNavigate();
  const [status, setStatus] = useState({});
  const logo = getLogo();

  useEffect(() => {
    if (searchParams.get('expired')) {
      showError('未登入或登入已過期，請重新登入！');
    }
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      setStatus(status);
    }
  }, []);

  const [showWeChatLoginModal, setShowWeChatLoginModal] = useState(false);

  const onWeChatLoginClicked = () => {
    setShowWeChatLoginModal(true);
  };

  const onSubmitWeChatVerificationCode = async () => {
    const res = await API.get(
      `/api/oauth/wechat?code=${inputs.wechat_verification_code}`
    );
    const { success, message, data } = res.data;
    if (success) {
      userDispatch({ type: 'login', payload: data });
      localStorage.setItem('user', JSON.stringify(data));
      navigate('/');
      // showSuccess('登入成功！');
      setShowWeChatLoginModal(false);
    } else {
      showError(message);
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    setSubmitted(true);
    if (username && password) {
      const res = await API.post(`/api/user/login`, {
        username,
        password
      });
      const { success, message, data } = res.data;
      if (success) {
        userDispatch({ type: 'login', payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        if (username === 'root' && password === '123456') {
          navigate('/user/edit');
          // showSuccess('登入成功！');
          showWarning('請立刻修改預設密碼！');
        } else {
          navigate('/token');
          showSuccess('登入成功！');
        }
      } else {
        showError(message);
      }
    }
  }

  const containerStyle = {
    height: '100vh',
    background: 'linear-gradient(to right, #f8f9fa, #e9ecef)',
  };

  const formContainerStyle = {
    maxWidth: 400,
    width: '100%',
    padding: '2.5rem',
    background: 'white',
    borderRadius: '8px',
    boxShadow: '0 10px 25px rgba(0, 0, 0, 0.05)',
  };

  const logoStyle = {
    width: '80px',
    marginBottom: '1.5rem',
  };

  const headerStyle = {
    color: '#2c3e50',
    marginBottom: '2rem',
  };

  const inputStyle = {
    marginBottom: '1.2rem',
  };

  const buttonStyle = {
    background: '#3498db',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '0.8rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    transition: 'background 0.3s ease',
  };

  const linkStyle = {
    color: '#3498db',
    textDecoration: 'none',
    fontWeight: '500',
  };

  return (
    <Grid textAlign='center' verticalAlign='middle' style={containerStyle}>
      <Grid.Column style={formContainerStyle}>
        <Image src={logo} style={logoStyle} centered />
        <Header as='h2' style={headerStyle}>
          用戶登入
        </Header>
        <Form size='large'>
          <Form.Input
            fluid
            icon='user'
            iconPosition='left'
            placeholder='使用者名稱 / 電子郵件地址'
            name='username'
            value={username}
            onChange={handleChange}
            style={inputStyle}
          />
          <Form.Input
            fluid
            icon='lock'
            iconPosition='left'
            placeholder='密碼'
            name='password'
            type='password'
            value={password}
            onChange={handleChange}
            style={inputStyle}
          />
          <Button fluid size='large' onClick={handleSubmit} style={buttonStyle}>
            登入
          </Button>
        </Form>
        <Divider horizontal style={{margin: '2rem 0'}}></Divider>
        <div style={{marginBottom: '1.5rem'}}>
          <Link to='/reset' style={linkStyle}>忘記密碼</Link> | <Link to='/register' style={linkStyle}>申請帳號</Link>
        </div>
        {status.github_oauth || status.wechat_login || status.lark_client_id ? (
          <div>
            <p style={{ color: '#7f8c8d', marginBottom: '1rem', fontWeight: '500' }}></p>
            <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
              {status.wechat_login && (
                <Button
                  circular
                  icon='wechat'
                  style={{ background: '#09B83E', color: 'white' }}
                  onClick={onWeChatLoginClicked}
                />
              )}
              {status.lark_client_id && (
                <Button
                  circular
                  style={{
                    background: "linear-gradient(135deg, #00D6B9, #2F73FF)",
                    padding: 0,
                    width: "40px",
                    height: "40px",
                  }}
                  onClick={() => onLarkOAuthClicked(status.lark_client_id)}
                >
                  <Image
                    src={larkIcon}
                    style={{ width: "24px", height: "24px", margin: "auto" }}
                  />
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </Grid.Column>

      {/* WeChat Login Modal */}
      <Modal
        onClose={() => setShowWeChatLoginModal(false)}
        onOpen={() => setShowWeChatLoginModal(true)}
        open={showWeChatLoginModal}
        size='tiny'
        dimmer='blurring'
      >
        <Modal.Content>
          <Modal.Description>
            <Header>微信登入</Header>
            <Image src={status.wechat_qrcode} size='medium' centered />
            <p style={{ textAlign: 'center', margin: '1em 0' }}>
              掃描二維碼關注公眾號，並輸入「驗證碼」獲取登入碼（三分鐘內有效）
            </p>
            <Form size='large'>
              <Form.Input
                fluid
                icon='qrcode'
                iconPosition='left'
                placeholder='輸入驗證碼'
                name='wechat_verification_code'
                value={inputs.wechat_verification_code}
                onChange={handleChange}
              />
              <Button
                color='green'
                fluid
                size='large'
                onClick={onSubmitWeChatVerificationCode}
              >
                確認登入
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </Grid>
  );
};

export default LoginForm;
